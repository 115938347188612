import React, { useState } from 'react';
import { BsChatDots } from 'react-icons/bs';
import { AiOutlineSearch, AiOutlineKey, AiOutlineSetting } from 'react-icons/ai';
import { FaTrash, FaPlus } from 'react-icons/fa';

const Sidebar = ({ onAPIClick, isSidebarOpen, toggleSidebar, sessions, selectSession, deleteSession, addNewSession, renameSession }) => {
  const [newSessionName, setNewSessionName] = useState('');
  const [renamingSessionId, setRenamingSessionId] = useState(null);
  const [renamingSessionName, setRenamingSessionName] = useState('');
  const [showOptions, setShowOptions] = useState(null);

  const handleNewSession = () => {
    if (newSessionName.trim()) {
      addNewSession({ id: Date.now(), name: newSessionName, chatHistory: [] });
      setNewSessionName('');
    } else {
      handleNewSessionWithoutName();
    }
  };

  const handleNewSessionWithoutName = () => {
    addNewSession({ id: Date.now(), name: "Session " + (sessions.length + 1), chatHistory: [] });
    setNewSessionName('');
  };

  const handleRenameSession = (sessionId) => {
    if (renamingSessionName.trim()) {
      renameSession(sessionId, renamingSessionName);
      setRenamingSessionId(null);
      setRenamingSessionName('');
    }
  };

  return (
    <div className={`w-64 h-screen bg-gray-800 text-white p-4 flex flex-col justify-between fixed top-0 left-0 z-50 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 transition-transform duration-300 ease-in-out`}>
      <div className="flex-1 overflow-y-auto">
        <div className="mb-8">
          <h1 className="text-2xl font-bold">New Chat</h1>
          <input
            type="text"
            className="mt-2 p-2 w-full bg-gray-700 text-white rounded"
            placeholder="New session name"
            value={newSessionName}
            onChange={(e) => setNewSessionName(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleNewSession()}
          />
          <button className="mt-2 p-2 bg-500 text-white rounded w-full flex items-center justify border" onClick={handleNewSession}>
            <FaPlus className="mr-2" /> Create Session
          </button>
        </div>
        <div className="flex items-center mb-4">
          <AiOutlineSearch className="text-xl" />
          <input type="text" className="ml-2 p-1 w-full bg-gray-700 text-white rounded" placeholder="Search chats..." />
        </div>
        <ul className="flex flex-col space-y-4">
          {sessions.map((session) => (
            <li key={session.id} className="flex items-center justify-between cursor-pointer">
              <div className="flex items-center" onClick={() => selectSession(session)}>
                <BsChatDots className="text-xl" />
                {renamingSessionId === session.id ? (
                  <input
                    type="text"
                    className="ml-2 p-1 w-full bg-gray-700 text-white rounded"
                    value={renamingSessionName}
                    onChange={(e) => setRenamingSessionName(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleRenameSession(session.id)}
                  />
                ) : (
                  <span className="ml-2">{session.name}</span>
                )}
              </div>
              <div className="relative">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowOptions((prev) => (prev === session.id ? null : session.id));
                  }}
                  className="text-white hover:text-gray-400 focus:outline-none"
                >
                  ...
                </button>
                {showOptions === session.id && (
                  <div className="absolute right-0 top-0 mt-6 bg-gray-700 rounded shadow-lg z-10">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setRenamingSessionId(session.id);
                        setRenamingSessionName(session.name);
                        setShowOptions(null);
                      }}
                      className="block px-4 py-2 text-sm text-yellow-500 hover:bg-gray-600"
                    >
                      Edit
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteSession(session.id);
                        setShowOptions(null);
                      }}
                      className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-600"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="bg-gray-900 p-4 rounded-lg mt-4">
        <div className="flex flex-col space-y-4 mb-4">
          <button className="flex items-center justify-between text-left bg-gray-700 p-2 rounded">
            <AiOutlineKey className="text-xl" />
            <span className="ml-2 flex-1">License Key</span>
            <span className="bg-red-500 text-white p-1 rounded">Login Here</span>
          </button>
          <button
            onClick={onAPIClick}
            className="flex items-center justify-between text-left bg-gray-700 p-2 rounded"
          >
            <AiOutlineSetting className="text-xl" />
            <span className="ml-2 flex-1">API Keys</span>
            <span className="bg-red-500 text-white p-1 rounded">Enter API Key</span>
          </button>
        </div>
        <button className="w-full bg-gradient-to-r from-orange-400 to-pink-500 text-white py-2 px-4 rounded mb-4">
          Bring AI to your team
        </button>
        <button
          className="absolute top-4 right-4 text-white md:hidden"
          onClick={toggleSidebar}
        >
          ✕
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
