import React from 'react';
import { AiFillYoutube } from 'react-icons/ai';
import { MdCode } from 'react-icons/md';
import { BsImage } from 'react-icons/bs';

const APIKeyCard = ({ icon, title, description }) => {
  return (
    <div className="p-4 bg-gray-100 rounded shadow flex items-center">
      {icon}
      <div className="ml-4">
        <h2 className="text-xl font-bold">{title}</h2>
        <p className="mt-2">{description}</p>
      </div>
    </div>
  );
};

export default APIKeyCard;
