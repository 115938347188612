import React, { forwardRef, memo, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus as theme } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import { SiOpenai, SiGoogle } from 'react-icons/si';
import 'tailwindcss/tailwind.css';

// Define icons associated with different models
const modelIcons = {
  'gpt-4o-mini': <SiOpenai className="text-xl mr-2" />,
  'gpt-4o': <SiOpenai className="text-xl mr-2" />,
  'gpt-3.5-turbo-16k': <SiOpenai className="text-xl mr-2" />,
  'gemini-1.5-pro-2M': <SiGoogle className="text-xl mr-2" />,
  'gemini-1.5-flash': <SiGoogle className="text-xl mr-2" />,
  // More models and icons can be added here
};

// ChatMessage component definition
const ChatMessage = memo(forwardRef(({ message, selectedModel }, ref) => {

  const isUser = message.role === 'user'; // Determine if the message is from the user
  const messageContent = message.content || ''; // Extract the message content

  const [storedModel, setStoredModel] = useState(message.model || selectedModel);

  // Set the stored model only once when the component mounts
  useEffect(() => {
    if (!message.model) {
      message.model = selectedModel;  // Save the selected model in the message object
    }
    setStoredModel(message.model);
  }, [message, selectedModel]);

  // Select the icon based on the stored model
  const modelIcon = modelIcons[storedModel] || null;

  // Return null if the message contains a tool call but no content
  if (message.tool_calls && message.content === '') {
    return null;
  }

  // Function to handle copying code to the clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  // Main component rendering
  return (
    <div
      ref={ref}
      className={`p-4 rounded-lg my-4 ${isUser ? 'bg-100 text-left' : 'text-left'} mx-auto w-full md:max-w-2xl lg:max-w-4xl`}
    >
      <div className="flex items-start">
        {/* Display the model icon for AIDoz messages */}
        <span className={isUser ? 'you-icon' : 'aidoz-response flex items-center'}>
          {isUser ? 'You' : modelIcon}
          {!isUser && <span className="ml-0"></span>}
        </span>
        <div className={`ml-2 p-4 flex-1 rounded-lg ${isUser ? 'bg-blue-100' : 'bg-gray-100 hover:bg-gray-50'} text-left`}>
          {messageContent && (
            <ReactMarkdown
              children={messageContent}
              remarkPlugins={[remarkGfm]}
              components={{
                h1: ({ node, ...props }) => <h1 className="text-2xl font-bold my-2" {...props} />,
                h2: ({ node, ...props }) => <h2 className="text-xl font-bold my-2" {...props} />,
                h3: ({ node, ...props }) => <h3 className="text-lg font-bold my-2" {...props} />,
                p: ({ node, ...props }) => <p className="my-2" {...props} />,
                ul: ({ node, ...props }) => <ul className="list-disc ml-4 my-2" {...props} />,
                ol: ({ node, ...props }) => <ol className="list-decimal ml-4 my-2" {...props} />,
                li: ({ node, ...props }) => <li className="my-1" {...props} />,
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '');
                  const codeString = String(children).replace(/\n$/, '');
                  return !inline && match ? (
                    <div className="relative my-2">
                      <button
                        onClick={() => handleCopy(codeString)}
                        className="absolute top-2 right-2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md hover:bg-gray-700"
                      >
                        Copy Code
                      </button>
                      <SyntaxHighlighter
                        style={theme}
                        language={match[1]}
                        PreTag="div"
                        customStyle={{
                          padding: '1rem',
                          borderRadius: '0.5rem',
                          backgroundColor: '#1e1e1e',
                          color: '#d4d4d4',
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word',
                        }}
                        {...props}
                      >
                        {codeString}
                      </SyntaxHighlighter>
                    </div>
                  ) : (
                    <span className={`${inline ? 'bg-gray-200 p-1 rounded' : ''}`} {...props}>
                      {children}
                    </span>
                  );
                },
                table: ({ node, ...props }) => (
                  <div className="overflow-auto my-4">
                    <table className="min-w-full bg-white border border-gray-300 divide-y divide-gray-200" {...props} />
                  </div>
                ),
                thead: ({ node, ...props }) => (
                  <thead className="bg-gray-50" {...props} />
                ),
                tbody: ({ node, ...props }) => (
                  <tbody className="bg-white divide-y divide-gray-200" {...props} />
                ),
                tr: ({ node, ...props }) => (
                  <tr className="hover:bg-gray-100" {...props} />
                ),
                th: ({ node, ...props }) => (
                  <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" {...props} />
                ),
                td: ({ node, ...props }) => (
                  <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500" {...props} />
                ),
              }}
            />
          )}
          {message.file && message.file.type && message.file.type.startsWith('image/') && (
            <img src={URL.createObjectURL(message.file)} alt="Uploaded file" className="mt-2 mx-auto max-h-48 rounded-lg shadow-lg" />
          )}
          {message.file && message.file.type && !message.file.type.startsWith('image/') && (
            <div className="mt-2 text-gray-600">{message.file.name}</div>
          )}
        </div>
      </div>
    </div>
  );
}));

export default ChatMessage;
