import React, { useState, useEffect, useRef } from 'react';
import { FaPaperclip, FaMicrophone, FaKeyboard, FaPen, FaTimes } from 'react-icons/fa';

const ChatInput = ({ message, setMessage, handleSendMessage, handleFileChange, file, setFile }) => {
  const [inputHeight, setInputHeight] = useState('auto');
  const inputRef = useRef(null);
  const fileInputRef = useRef(null); // Ref for the file input

  useEffect(() => {
    if (inputRef.current) {
      setInputHeight('auto'); // Reset height before calculating from scrollHeight
      setInputHeight(`${inputRef.current.scrollHeight}px`); // Set height based on content
    }
  }, [message]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleSend = () => {
    if (message.trim() || file) { // Check if the message is not empty or file is uploaded
      handleSendMessage();
      setMessage(''); // Reset message input
      setFile(null); // Reset file input
      setInputHeight('auto'); // Reset the input height
      if (fileInputRef.current) {
        fileInputRef.current.value = null; // Clear the file input value
      }
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Clear the file input value
    }
  };

  return (
    <div className="fixed bottom-0 left-0 w-full flex justify-center p-2 bg-white border-t border-gray-300 md:pl-64">
      <div className="flex items-center w-full max-w-3xl mx-auto space-x-2">
        {/* <button aria-label="Keyboard" className="p-2 hidden md:block">
          <FaKeyboard className="text-gray-600" />
        </button>
        <button aria-label="Pen" className="p-2 hidden md:block">
          <FaPen className="text-gray-600" />
        </button> */}
        <textarea
          ref={inputRef}
          rows={1}
          style={{ height: inputHeight, maxHeight: '150px' }}
          className="flex-1 border p-2 rounded resize-none overflow-auto"
          placeholder="Type your message here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          aria-label="Chat message input"
        />
        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          onChange={handleFileChange}
          ref={fileInputRef} // Assign ref to the file input
        />
        <button
          aria-label="Attach file"
          className="p-2"
          onClick={() => document.getElementById('fileInput').click()}
        >
          <FaPaperclip className="text-gray-600" />
        </button>
        {/* <button aria-label="Microphone" className="p-2">
          <FaMicrophone className="text-gray-600" />
        </button> */}
        <button onClick={handleSend} className="bg-blue-500 text-white py-1 px-3 rounded">
          Send
        </button>
      </div>
      {file && (
        <div className="flex items-center mt-2">
          {file.type.startsWith('image/') ? (
            <img src={URL.createObjectURL(file)} alt="Preview" className="w-16 h-16 object-cover rounded" />
          ) : (
            <span className="text-gray-600">{file.name}</span>
          )}
          <button onClick={handleRemoveFile} className="ml-2 p-1">
            <FaTimes className="text-red-600" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatInput;
