import React, { useState, useEffect } from 'react';
import Sidebar from './components/common/Sidebar';
import MainContent from './pages/MainContent/MainContent';
import APIModal from './components/common/APIModal';
import { loadSessionsFromLocalStorage, saveSessionsToLocalStorage } from './utils/localStorageUtils';
import './assets/styles/App.css';

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sessions, setSessions] = useState(loadSessionsFromLocalStorage());
  const [activeSession, setActiveSession] = useState(null);
  const [selectedModel, setSelectedModel] = useState('gpt-4o-mini');

  useEffect(() => {
    saveSessionsToLocalStorage(sessions);
  }, [sessions]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const addNewSession = (session) => {
    setSessions((prevSessions) => {
      const updatedSessions = [...prevSessions, session];
      saveSessionsToLocalStorage(updatedSessions);
      return updatedSessions;
    });
    setActiveSession(session);
  };

  const renameSession = (sessionId, newName) => {
    setSessions((prevSessions) => {
      const updatedSessions = prevSessions.map(session =>
        session.id === sessionId ? { ...session, name: newName } : session
      );
      saveSessionsToLocalStorage(updatedSessions);
      return updatedSessions;
    });
  };

  const selectSession = (session) => {
    setActiveSession(session);
  };

  const deleteSession = (sessionId) => {
    setSessions((prevSessions) => {
      const updatedSessions = prevSessions.filter(session => session.id !== sessionId);
      saveSessionsToLocalStorage(updatedSessions);
      return updatedSessions;
    });
    if (activeSession?.id === sessionId) {
      setActiveSession(null);
    }
  };

  return (
    <div className="flex">
      <Sidebar
        onAPIClick={() => setIsModalOpen(true)}
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        sessions={sessions}
        selectSession={selectSession}
        deleteSession={deleteSession}
        addNewSession={addNewSession}
        renameSession={renameSession}
      />
      <MainContent
        toggleSidebar={toggleSidebar}
        activeSession={activeSession}
        addNewSession={addNewSession}
        sessions={sessions}
        setSessions={setSessions}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
      />
      <APIModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default App;
