import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className="flex flex-col items-center mt-10">
      <div className="relative">
        <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-500"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-lg font-semibold text-blue-500">AI</span>
        </div>
      </div>
      <p className="mt-6 text-lg text-center text-gray-700">
        Our AI agents are working hard for you now...
      </p>
    </div>
  );
};

export default LoadingSpinner;


export const ImageGenerationLoader = () => {
  return (
    <div className="flex flex-col items-center mt-10">
      <div className="relative">
        <div className="animate-spin-slow rounded-full h-32 w-32 border-t-4 border-b-4 border-purple-500"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <svg
            className="w-16 h-16 text-purple-500 animate-pulse"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 3v18m9-9H3"
            />
          </svg>
        </div>
      </div>
      <p className="mt-6 text-lg text-center text-purple-600">
        Crafting your masterpiece...
      </p>
      <p className="text-sm text-center text-gray-500">
        This might take a few moments, but the wait will be worth it!
      </p>
    </div>
  );
};

 



