import React from 'react';

const WelcomeMessage = () => {
  return (
    <div className="text-center mb-8">
      <h1 className="text-4xl md:text-6xl font-bold">AIDoz</h1>
      <p className="mt-4 text-gray-600">GPT-4 Mini is available!</p>
    </div>
  );
};

export default WelcomeMessage;
