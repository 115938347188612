import React from 'react';
import APIKeyCard from './APIKeyCard';
import { AiFillYoutube } from 'react-icons/ai';
import { MdCode } from 'react-icons/md';
import { BsImage } from 'react-icons/bs';

const CardGrid = () => {
  const cards = [
    {
      icon: <AiFillYoutube className="text-3xl text-red-600" />,
      title: 'YouTube Content Writer',
      description: 'A YouTube content writer specialized in creating engaging and high-performing...'
    },
    {
      icon: <MdCode className="text-3xl text-blue-600" />,
      title: 'Pro Coder',
      description: 'Help you write code without overexplaining things using only its internal...'
    },
    {
      icon: <BsImage className="text-3xl text-yellow-600" />,
      title: 'Blog Image Generator',
      description: 'A blog image generator specialized in creating modern vector illustrations for...'
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
      {cards.map((card, index) => (
        <APIKeyCard key={index} {...card} />
      ))}
    </div>
  );
};

export default CardGrid;
