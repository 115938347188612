import React, { useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { AiFillEye, AiFillApi } from 'react-icons/ai';
import { SiOpenai, SiGoogle } from 'react-icons/si';

const modelIcons = {
  'gpt-4o-mini-2024-07-18': <SiOpenai />,
  'gpt-4o-mini': <SiOpenai />,
  'gpt-4o-2024-05-13': <SiOpenai />,
  'gpt-4o': <SiOpenai />,
  'gpt-4-turbo-preview': <SiOpenai />,
  'gpt-4-turbo-2024-04-09': <SiOpenai />,
  'gpt-4-turbo': <SiOpenai />,
  'gpt-4-1106-preview': <SiOpenai />,
  'gpt-4-0613': <SiOpenai />,
  'gpt-4-0125-preview': <SiOpenai />,
  'gpt-4': <SiOpenai />,
  'gpt-3.5-turbo-16k': <SiOpenai />,
  'gpt-3.5-turbo-1106': <SiOpenai />,
  'gpt-3.5-turbo-0125': <SiOpenai />,
  'gpt-3.5-turbo': <SiOpenai />,
  'gemini-1.5-pro-2M': <SiGoogle />,
  'gemini-1.5-flash': <SiGoogle />,
  'gemini-1.5-pro-exp-0801': <SiGoogle />
};

const models = [
  // { name: 'gemini-1.5-pro-exp-0801', views: '2M', new: false },
  // { name: 'gpt-4o-mini-2024-07-18', views: '128K', new: false },
  // { name: 'gpt-4o-mini', views: '128K', new: true },
  // { name: 'gpt-4o-2024-05-13', views: '128K', new: false },
  // { name: 'gpt-4o', views: '128K', new: false },
  // { name: 'gpt-4-turbo-preview', views: '8K', new: false },
  // { name: 'gpt-4-turbo-2024-04-09', views: '128K', new: false },
  // { name: 'gpt-4-turbo', views: '128K', new: false },
  // { name: 'gpt-4-1106-preview', views: '128K', new: false },
  // { name: 'gpt-4-0613', views: '128K', new: false },
  // { name: 'gpt-4-0125-preview', views: '128K', new: false },
  // { name: 'gpt-4', views: '8K', new: false },
  // { name: 'gpt-3.5-turbo-16k', views: '16K', new: false },
  // { name: 'gpt-3.5-turbo-1106', views: '16K', new: false },
  // { name: 'gpt-3.5-turbo-0125', views: '16K', new: false },
  // { name: 'gpt-3.5-turbo', views: '128K', new: false },
  // { name: 'gemini-1.5-pro-2M', views: '2M', new: true },
  // { name: 'gemini-1.5-flash', views: '1M', new: false },

  { value : 'GPT-4o-Mini',name: 'gpt-4o-mini', views: '128K', new: true },
  { value : 'GPT-4o',name: 'gpt-4o', views: '128K', new: false },
  { value : 'GPT-3.5',name: 'gpt-3.5-turbo-16k', views: '16K', new: false },
  { value : 'Gemini Pro',name: 'gemini-1.5-pro-2M', views: '2M', new: true },
  { value : "Gemini",name: 'gemini-1.5-flash', views: '1M', new: false },

  
];

const ModelSelection = ({ selectedModel, setSelectedModel }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const selectedIcon = modelIcons[selectedModel];

  return (
    <div className="relative">
      <button 
        onClick={() => setDropdownOpen(!dropdownOpen)} 
        className="flex items-center bg-white text-gray-800 py-2 px-4 rounded min-w-[250px] truncate"
      >
        <span className="text-xl mr-2">{selectedIcon}</span>
        {selectedModel} <FaCaretDown className="ml-2" />
      </button>
      {dropdownOpen && (
        <ul className="absolute mt-2 bg-white shadow-lg rounded w-full z-10 max-h-60 overflow-y-auto min-w-[300px]">
          {models.map((model) => (
            <li 
              key={model.value} 
              className="flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => { setSelectedModel(model.name); setDropdownOpen(false); }}
            >
              <div className="flex items-center space-x-2">
                <span className="text-xl">{modelIcons[model.value]}</span>
                <span className="font-semibold">{model.value}</span>
                {model.new && <span className="ml-2 text-xs text-green-500">NEW</span>}
              </div>
              <div className="flex items-center space-x-2">
                <AiFillApi className="text-blue-500" />
                <AiFillEye className="text-orange-500" />
                <span>{model.views}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ModelSelection;
