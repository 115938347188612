// src/utils/localStorageUtils.js

export const loadSessionsFromLocalStorage = () => {
  const sessions = localStorage.getItem('sessions');
  return sessions ? JSON.parse(sessions) : [];

};

export const saveSessionsToLocalStorage = (sessions) => {
  localStorage.setItem('sessions', JSON.stringify(sessions));

};
