import React, { useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';

const functions = [
    { name: 'DALL-E Image Generation', value: 'image_generation_via_dalle_3', 
        description: "Generate images using DALL-E 3 based on image descriptions. Adhere to guidelines such as English translation, not depicting politicians or recent artists, and ensuring artist references are over 100 years old.",
        parameters : {
            type: "object",
            required: ["prompt"],
            properties: {
              prompt: {
                type: "string",
                description: "Generate images using DALL-E 3 based on image descriptions. Adhere to guidelines such as English translation, not depicting politicians or recent artists, and ensuring artist references are over 100 years old."
              }
            }
          } },
          {
            name: "Google Search",
            value: 'get_web_search_results',
            description: "Search for information from the internet in real-time using Google Search.",
            parameters: {
              type: "object",
              required: ["keyword"],
              properties: {
                keyword: {
                  type: "string",
                  description: "The search keyword"
                }
              },
            }
          }
    // Add more functions here as needed
];

const FunctionSelection = ({ selectedFunctions, setSelectedFunctions }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleFunctionToggle = (func) => {
        setSelectedFunctions((prevSelectedFunctions) => {
            if (prevSelectedFunctions.includes(func)) {
                return prevSelectedFunctions.filter(f => f !== func);
            } else {
                return [...prevSelectedFunctions, func];
            }
        });
    };

    return (
        <div className="relative">
            <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="flex items-center bg-white text-gray-800 py-2 px-4 rounded min-w-[250px] truncate"
            >
                {selectedFunctions.length > 0 ? `${selectedFunctions.length} function(s) selected` : 'Select Functions'} <FaCaretDown className="ml-2" />
            </button>
            {dropdownOpen && (
                <ul className="absolute mt-2 bg-white shadow-lg rounded w-full z-10 max-h-60 overflow-y-auto min-w-[300px]">
                    {functions.map((func) => (
                        <li
                            key={func.value}
                            className="flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleFunctionToggle(func)}
                        >
                            <span className="font-semibold">{func.name}</span>
                            <input type="checkbox" checked={selectedFunctions.includes(func)} readOnly />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default FunctionSelection;