import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import * as XLSX from 'xlsx';
import Header from './Header';
import WelcomeMessage from './WelcomeMessage';
import CardGrid from './CardGrid';
import ChatSection from './ChatSection';
import image_generation_via_dalle_3 from './../../plugins/dalle'
import get_web_search_results from './../../plugins/googleSearch'
import mammoth from 'mammoth';
import pptxParser from 'pptx-parser';



const MainContent = ({ toggleSidebar, activeSession, addNewSession, sessions, setSessions, selectedModel, setSelectedModel }) => {
  GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs';

  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [abortController, setAbortController] = useState(null);
  const [file, setFile] = useState(null);
  const [prevActiveSession, setPrevActiveSession] = useState(null);
  const [unifiedContext, setUnifiedContext] = useState([]);
  const [selectedFunctions, setSelectedFunctions] = useState([]);

  useEffect(() => {
    if (activeSession && activeSession !== prevActiveSession) {
      setChatHistory(activeSession.chatHistory || []);
      setPrevActiveSession(activeSession);
      setUnifiedContext(activeSession.chatHistory || []);
    }
  }, [activeSession, prevActiveSession]);

  useEffect(() => {
    if (chatHistory.length > 0) {
      if (activeSession) {
        const updatedSession = {
          ...activeSession,
          chatHistory: chatHistory,
        };
        setSessions(sessions.map((session) => (session.id === activeSession.id ? updatedSession : session)));
      } else {
        const newSession = {
          id: Date.now(),
          name: `Session ${sessions.length + 1}`,
          chatHistory: chatHistory,
        };
        addNewSession(newSession);
        setPrevActiveSession(newSession);
        setChatHistory(chatHistory);
      }
    }
  }, [chatHistory]);
  const updateUnifiedContext = (message) => {
    setUnifiedContext((prevContext) => [...prevContext, message]);
  };

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000; // 1 second

  const handleStop = () => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
      setIsLoading(false);
    }
  };


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleSendMessage = async (retryMessage = null, originalMessage = null, payload = null) => {
    const msg = originalMessage || message;
  
    if (!msg.trim() && !file) return;
  
    const newMessage = { role: 'user', content: msg, file: file };
    const updatedChatHistory = [...chatHistory, newMessage];
    setChatHistory(updatedChatHistory);
    setMessage('');
    setFile(null);
    const tools = selectedFunctions.map(func => ({
      type: "function",
      function: {
        name: func.value,
        parameters: func.parameters,
        description: func.description
      }
    }));
  
    updateUnifiedContext(newMessage);
    const contextMessages = unifiedContext.slice(-5);
  
    if (selectedModel.toLowerCase().includes('gemini')) {
      let geminiContents = [
        { role: 'user', parts: [{ text: "SYSTEM INSTRUCTION: You are a helpful AI assistant. Today is " + new Date().toString() + ".\nIf you need to display math symbols and expressions, put them in double dollar signs \"$$\" (example: $$ x - 1 $$)" }] },
        { role: 'model', parts: [{ text: 'Understood' }] }
      ];
  
      contextMessages.forEach(message => {
        if (message.role === 'user') {
          geminiContents.push({ role: 'user', parts: [{ text: message.content }] });
        } else if (message.role === 'assistant') {
          geminiContents.push({ role: 'model', parts: [{ text: message.content }] });
        }
      });
  
      if (file) {
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const base64Image = e.target.result.split(',')[1]; // Extract base64 data
  
            payload = {
              contents: [
                ...geminiContents,
                { role: 'user', parts: [{ text: msg }, { inlineData: { mimeType: file.type, data: base64Image } }] }
              ],
              generationConfig: {}
            };
            await sendGeminiPayload(payload, newMessage);
          };
          reader.readAsDataURL(file);
        } else if (file.type === 'application/pdf') {
          await handlePDFFileGemini(file, msg, geminiContents);
        } else if (file.type === 'text/csv') {
          await handleCSVFileGemini(file, msg, geminiContents);
        } else if (file.type.startsWith('text/')) {
          await handleTextFileGemini(file, msg, geminiContents);
        }
      } else {
        payload = {
          contents: [
            ...geminiContents,
            { role: 'user', parts: [{ text: msg }] }
          ],
          generationConfig: {}
        };
        await sendGeminiPayload(payload, newMessage);
      }
    } else {
      // Prepare the payload with function calls
      payload = payload || {
        model: selectedModel.toLowerCase().replace(/ /g, '-'),
        stream: true,
        stream_options: { include_usage: true },
        messages: [
          { role: "system", content: "You are a helpful AI assistant. Today is " + new Date().toString() + ".\nIf you need to display math symbols and expressions, put them in double dollar signs \"$$\" (example: $$ x - 1 $$)" },
          ...contextMessages
        ],
        tools: tools.length > 0 ? tools : undefined,
        tool_choice: tools.length > 0 ? "auto" : undefined,
      };
  
      if (file) {
        if (file.type === 'application/pdf') {
          await handlePDFFile(file, msg, payload);
        } else if (file.type.startsWith('image/')) {
          await handleImageFile(file, msg, payload);
        } else if (file.type === 'text/csv') {
          await handleCSVFile(file, msg, payload);
        } else if (file.type.startsWith('text/')) {
          await handleTextFile(file, msg, payload);
        }else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
          await handleExcelFile(file, msg, payload);
        } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/msword') {
          await handleDocFile(file, msg, payload);
        } else if (file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || file.type === 'application/vnd.ms-powerpoint') {
          await handlePptFile(file, msg, payload);
        }
      } else {
        payload.messages.push({ role: 'user', content: msg });
        const originalMessageForRetry = { ...newMessage, payload };
        await sendPayload(payload, originalMessageForRetry);
      }
    }
  };
  
  

  const handlePDFFileGemini = async (file, msg, geminiContents) => {
    const loadingTask = getDocument(URL.createObjectURL(file));
    const pdf = await loadingTask.promise;
    const numPages = pdf.numPages;
    let content = '';

    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      textContent.items.forEach(item => {
        content += item.str + ' ';
      });
    }

    const payload = {
      contents: [
        ...geminiContents,
        { role: 'user', parts: [{ text: `\n<FILE_ATTACHMENT>\n<FILE_NAME>\n${file.name}\n</FILE_NAME>\n<FILE_CONTENT>\n${content}\n</FILE_CONTENT>\n</FILE_ATTACHMENT>\n\n\n${msg}` }] }
      ],
      generationConfig: {}
    };
    await sendGeminiPayload(payload, { role: 'user', content: msg, file: file });
  };

  const handleCSVFileGemini = (file, msg, geminiContents) => {
    Papa.parse(file, {
      complete: async function (results) {
        const content = results.data.map(row => row.join(',')).join('\n');
        const payload = {
          contents: [
            ...geminiContents,
            { role: 'user', parts: [{ text: `\n<FILE_ATTACHMENT>\n<FILE_NAME>\n${file.name}\n</FILE_NAME>\n<FILE_CONTENT>\n${content}\n</FILE_CONTENT>\n</FILE_ATTACHMENT>\n\n\n${msg}` }] }
          ],
          generationConfig: {}
        };
        await sendGeminiPayload(payload, { role: 'user', content: msg, file: file });
      },
      error: function (error) {
        console.error('Error parsing CSV:', error);
      }
    });
  };
  const handleTextFileGemini = (file, msg, geminiContents) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result;
      const payload = {
        contents: [
          ...geminiContents,
          { role: 'user', parts: [{ text: `\n<FILE_ATTACHMENT>\n<FILE_NAME>\n${file.name}\n</FILE_NAME>\n<FILE_CONTENT>\n${content}\n</FILE_CONTENT>\n</FILE_ATTACHMENT>\n\n\n${msg}` }] }
        ],
        generationConfig: {}
      };
      await sendGeminiPayload(payload, { role: 'user', content: msg, file: file });
    };
    reader.readAsText(file);
  };

  const sendGeminiPayload = async (payload, originalMessage) => {
    const googleKey = localStorage.getItem('googleKey');
    const controller = new AbortController();
    setAbortController(controller);
    setIsLoading(true);
    setError(null);

    let url;
    if (selectedModel.toLowerCase().includes('flash')) {
      url = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:streamGenerateContent';
    } else if (selectedModel.toLowerCase().includes('pro')) {
      url = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-pro:streamGenerateContent';
    } else {
      setError('Unknown model selected.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${url}?key=${googleKey}&alt=sse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
        signal: controller.signal
      });

      if (!response.ok) {
        const errorText = await response.text();
        const jsonPart = errorText.match(/{.*}/s);
        let errorMessage;
        let errorJson;

        if (jsonPart) {
          try {
            errorJson = JSON.parse(jsonPart[0]);
            errorMessage = errorJson.error.message || errorText;
          } catch (e) {
            errorMessage = errorText;
          }
        } else {
          errorMessage = errorText;
        }

        setError(`Error ${response.status}: ${errorMessage}`);
        setIsLoading(false);
        return;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let assistantMessage = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n').filter(line => line.trim());

        for (let line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(6));
              if (data.candidates && data.candidates.length > 0) {
                const parts = data.candidates[0].content.parts;
                parts.forEach(part => {
                  const delta = part.text;
                  assistantMessage += delta;
                  setChatHistory((prevHistory) => {
                    const lastMessage = prevHistory[prevHistory.length - 1];
                    if (lastMessage && lastMessage.role === 'assistant') {
                      return [...prevHistory.slice(0, -1), { ...lastMessage, content: lastMessage.content + delta }];
                    } else {
                      return [...prevHistory, { role: 'assistant', content: delta }];
                    }
                  });
                });
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
        await new Promise(resolve => setTimeout(resolve, 50));
      }
      console.log("geminiapi " + assistantMessage)
      updateUnifiedContext({ role: 'assistant', content: assistantMessage });

      setIsLoading(false);

    } catch (error) {
      if (controller.signal.aborted) {
        console.log('Fetch aborted');
        setIsLoading(false);
        return;
      }
      console.error('Error during fetch:', error);
      setError(error.message || 'An error occurred. Please try again.');
      setIsLoading(false);
    }
  };

  const handleDocFile = async (file, msg, payload) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const arrayBuffer = e.target.result;
      try {
        const result = await mammoth.extractRawText({ arrayBuffer });
        const content = result.value;
  
        payload.messages.push({
          role: "user",
          content: `\n<FILE_ATTACHMENT>\n<FILE_NAME>\n${file.name}\n</FILE_NAME>\n<FILE_CONTENT>\n${content}\n</FILE_CONTENT>\n</FILE_ATTACHMENT>\n\n\n${msg}`
        });
        await sendPayload(payload);
      } catch (error) {
        console.error('Error processing DOC file:', error);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handlePptFile = async (file, msg, payload) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const arrayBuffer = e.target.result;
      try {
        const slides = await pptxParser(arrayBuffer);
        let content = '';
  
        slides.forEach((slide, index) => {
          content += `Slide ${index + 1}:\n${slide.text}\n\n`;
        });
  
        payload.messages.push({
          role: "user",
          content: `\n<FILE_ATTACHMENT>\n<FILE_NAME>\n${file.name}\n</FILE_NAME>\n<FILE_CONTENT>\n${content}\n</FILE_CONTENT>\n</FILE_ATTACHMENT>\n\n\n${msg}`
        });
        await sendPayload(payload);
      } catch (error) {
        console.error('Error processing PPT file:', error);
      }
    };
    reader.readAsArrayBuffer(file);
  };
  
  const handlePDFFile = async (file, msg, payload) => {
    const loadingTask = getDocument(URL.createObjectURL(file));
    const pdf = await loadingTask.promise;
    const numPages = pdf.numPages;
    let content = '';

    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      textContent.items.forEach(item => {
        content += item.str + ' ';
      });
    }

    payload.messages.push({
      role: "user",
      content: `\n<FILE_ATTACHMENT>\n<FILE_NAME>\n${file.name}\n</FILE_NAME>\n<FILE_CONTENT>\n${content}\n</FILE_CONTENT>\n</FILE_ATTACHMENT>\n\n\n${msg}`
    });
    await sendPayload(payload);
  };

  const handleCSVFile = (file, msg, payload) => {
    Papa.parse(file, {
      complete: function (results) {
        const content = results.data.map(row => row.join(',')).join('\n');
        payload.messages.push({
          role: "user",
          content: `\n<FILE_ATTACHMENT>\n<FILE_NAME>\n${file.name}\n</FILE_NAME>\n<FILE_CONTENT>\n${content}\n</FILE_CONTENT>\n</FILE_ATTACHMENT>\n\n\n${msg}`
        });
        sendPayload(payload);
      },
      error: function (error) {
        console.error('Error parsing CSV:', error);
      }
    });
  };

  const handleTextFile = (file, msg, payload) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result;
      payload.messages.push({
        role: "user",
        content: `\n<FILE_ATTACHMENT>\n<FILE_NAME>\n${file.name}\n</FILE_NAME>\n<FILE_CONTENT>\n${content}\n</FILE_CONTENT>\n</FILE_ATTACHMENT>\n\n\n${msg}`
      });
      await sendPayload(payload);
    };
    reader.readAsText(file);
  };

  const handleImageFile = (file, msg, payload) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result.split(',')[1]; // Extract base64 data
      payload.messages.push({
        role: "user",
        content: [
          { type: "text", text: msg },
          { type: "image_url", "image_url": { url: `data:image/jpeg;base64,${content}`, "detail": "high" } }
        ]
      });
      await sendPayload(payload);
    };
    reader.readAsDataURL(file);
  };

  const handleExcelFile = async (file, msg, payload) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
  
      let content = '';
      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const sheetContent = XLSX.utils.sheet_to_csv(worksheet);
        content += `Sheet: ${sheetName}\n${sheetContent}\n`;
      });
  
      payload.messages.push({
        role: "user",
        content: `\n<FILE_ATTACHMENT>\n<FILE_NAME>\n${file.name}\n</FILE_NAME>\n<FILE_CONTENT>\n${content}\n</FILE_CONTENT>\n</FILE_ATTACHMENT>\n\n\n${msg}`
      });
      await sendPayload(payload);
    };
    reader.readAsArrayBuffer(file);
  };
 const sendPayload = async (payload, originalMessage) => {
  const openAIKey = localStorage.getItem('openAIKey');
  const controller = new AbortController();
  setAbortController(controller);
  setIsLoading(true);
  setError(null);

  for (let retries = 0; retries < MAX_RETRIES; retries++) {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${openAIKey}`
        },
        body: JSON.stringify(payload),
        signal: controller.signal
      });

      if (!response.ok) {
        const errorText = await response.text();
        let errorMessage;

        try {
          const errorJson = JSON.parse(errorText);
          errorMessage = errorJson.error.message || errorText;
        } catch {
          errorMessage = errorText;
        }

        setError(`Error ${response.status}: ${errorMessage}`);
        setIsLoading(false);
        return originalMessage;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let isDone = false;
      let assistantMessage = '';
      let functionCalls = [];

      while (!isDone) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n').filter(line => line.trim());

        for (let line of lines) {
          if (line === 'data: [DONE]') {
            isDone = true;
            break;
          }
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(6));

              if (data.choices && data.choices.length > 0) {
                const delta = data.choices[0].delta;

                // Check for tool call information
                if (delta.tool_calls) {
                  delta.tool_calls.forEach((toolCall) => {
                    if (functionCalls[toolCall.index]) {
                      functionCalls[toolCall.index].arguments += toolCall.function.arguments || '';
                    } else {
                      functionCalls[toolCall.index] = { name: toolCall.function.name, arguments: toolCall.function.arguments || '', id: toolCall.id };
                    }
                     // Update the context with the tool call
                  setChatHistory((prevHistory) => [
                    ...prevHistory,
                    {
                      role: 'assistant',
                      content: '',
                      tool_calls: [
                        {
                          id: toolCall.id,
                          type: 'function',
                          function: {
                            name: toolCall.function.name,
                            arguments: toolCall.function.arguments || ''
                          }
                        }
                      ]
                    }
                  ]);
                  });

                 
                }

                // Accumulate regular content
                if (delta.content) {
                  assistantMessage += delta.content;
                  setChatHistory((prevHistory) => {
                    const lastMessage = prevHistory[prevHistory.length - 1];
                    if (lastMessage && lastMessage.role === 'assistant') {
                      return [...prevHistory.slice(0, -1), { ...lastMessage, content: lastMessage.content + delta.content }];
                    } else {
                      return [...prevHistory, { role: 'assistant', content: delta.content }];
                    }
                  });
                }
              }

              if (data.choices[0]?.finish_reason === 'stop') {
                isDone = true;
              }
            } catch (parseError) {
              console.error('JSON parse error', parseError);
            }
          }
        }

        await new Promise(resolve => setTimeout(resolve, 50));
      }

      // Handle all the function calls
      if (isDone && functionCalls.length > 0) {
        try {
          const functionResults = await Promise.all(
            functionCalls.map(async (functionCall) => {
              if (functionCall && functionCall.name === 'image_generation_via_dalle_3') {
                try {
                  const functionArgs = JSON.parse(functionCall.arguments);
                  const result = await image_generation_via_dalle_3(functionArgs);
                  return {
                    role: 'assistant',
                    content: result
                  };
                } catch (error) {
                  console.error('Error handling function call:', error);
                  return {
                    role: 'assistant',
                    content: 'Failed to process function call.'
                  };
                }
              } else if (functionCall && functionCall.name === 'get_web_search_results') {
                try {
                  const functionArgs = JSON.parse(functionCall.arguments);
                  const result = await get_web_search_results(functionArgs);

                  // // Add the tool result to the chat history
                  // setChatHistory(prevHistory => [
                  //   ...prevHistory,
                  //   { role: 'tool', content: result }
                  // ]);

                  // Now send the updated context back to the AI for a new completion
                  const newPayload = {
                    ...payload,
                    messages: [
                      ...payload.messages,
                      { role: 'assistant', content: '', tool_calls: [{ id: functionCall.id, type: 'function', function: { name: functionCall.name, arguments: functionCall.arguments } }] },
                      { role: 'tool', content: result ,tool_call_id: functionCall.id,name :  functionCall.name}
                    ]
                  };
                  
                  // Recursively call sendPayload with the new context
                  await sendPayload(newPayload, originalMessage);
                  return null; // No need to return the result here, as it will be handled in the recursive call

                } catch (error) {
                  console.error('Error handling function call:', error);
                  return {
                    role: 'assistant',
                    content: 'Failed to process function call.'
                  };
                }
              } else {
                return null; // Ignore if not a recognized function call
              }
            })
          );

          // Add results to chat history in the correct order
          setChatHistory(prevHistory => [
            ...prevHistory,
            ...functionResults.filter(result => result !== null)
          ]);

          // Update unified context as well
          functionResults.forEach(result => {
            if (result) {
              updateUnifiedContext(result);
            }
          });
        } catch (error) {
          console.error('Error executing function calls:', error);
          setError('An error occurred while processing function calls.');
        }
      }

      console.log("openai: " + assistantMessage);
      updateUnifiedContext({ role: 'assistant', content: assistantMessage });

      setIsLoading(false);
      return;

    } catch (error) {
      if (controller.signal.aborted) {
        console.log('Fetch aborted');
        setIsLoading(false);
        return;
      }
      console.error('Error during fetch:', error);
      if (retries < MAX_RETRIES - 1) {
        console.log(`Retrying... (${retries + 1}/${MAX_RETRIES})`);
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      } else {
        setError(error.message || 'An error occurred. Please try again.');
        setIsLoading(false);
        return originalMessage;
      }
    }
  }
};
  
  


  return (
    <div className="ml-0 md:ml-64 flex-1 p-4 sm:p-8 bg-white text-black relative">
      <Header toggleSidebar={toggleSidebar} setSelectedModel={setSelectedModel} selectedModel={selectedModel} selectedFunctions={selectedFunctions} setSelectedFunctions={setSelectedFunctions}/>
      <div className="mt-20 mb-20">
        {chatHistory.length === 0 && (
          <>
            <WelcomeMessage />
            <CardGrid />
          </>
        )}
        <ChatSection
          chatHistory={chatHistory}
          setChatHistory={setChatHistory}
          message={message}
          setMessage={setMessage}
          handleSendMessage={handleSendMessage}
          isLoading={isLoading}
          error={error}
          handleStop={handleStop}
          handleFileChange={handleFileChange}
          file={file}
          setFile={setFile}
          selectedModel={selectedModel}
        />
      </div>
    </div>
  );
};

export default MainContent;
