import React, { useEffect, useRef } from 'react';
import ChatInput from '../../components/Chat/ChatInput';
import ChatMessage from '../../components/Chat/ChatMessage';
import LoadingSpinner from '../../components/Chat/LoadingSpinner';

const ChatSection = ({ chatHistory, setChatHistory, message, setMessage, handleSendMessage, isLoading, error, handleStop, handleFileChange, file, setFile, retryPayload ,selectedModel }) => {
  const chatContainerRef = useRef(null);
  const latestMessageRef = useRef(null);

  useEffect(() => {
    if (latestMessageRef.current) {
      latestMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory, isLoading]);

  return (
    <div className="relative flex flex-col h-full">
      <div className="flex-1 mb-4" ref={chatContainerRef}>
        {chatHistory.map((msg, index) => (
          <ChatMessage key={index} message={msg} ref={index === chatHistory.length - 1 ? latestMessageRef : null} selectedModel = {selectedModel}/>
        ))}
        {isLoading && <LoadingSpinner />}
      </div>
      {isLoading && (
        <div className="sticky bottom-16 left-0 w-full flex justify-center p-4 text-red-700">
          <button onClick={handleStop} className="bg-red-500 text-white px-4 py-2 rounded">
            Stop
          </button>
        </div>
      )}
      {error && (
        <div className="sticky bottom-32 left-0 w-full flex justify-center p-4 bg-red-100 border border-red-400 text-red-700">
          <p>Error occurred: {error} <button onClick={() => handleSendMessage(retryPayload?.content, null, retryPayload?.payload)} className="underline">Retry</button></p>
        </div>
      )}
      <ChatInput
        message={message}
        setMessage={setMessage}
        handleSendMessage={handleSendMessage}
        handleFileChange={handleFileChange}
        file={file}
        setFile={setFile}
      />
    </div>
  );
};

export default ChatSection;
