import React, { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible, AiFillLock } from 'react-icons/ai';
import { FaSave } from 'react-icons/fa';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

const APIModal = ({ isOpen, onClose }) => {
  const [openAIKey, setOpenAIKey] = useState(localStorage.getItem('openAIKey') || '');
  const [anthropicKey, setAnthropicKey] = useState(localStorage.getItem('anthropicKey') || '');
  const [googleKey, setGoogleKey] = useState(localStorage.getItem('googleKey') || '');
  const [showOpenAIKey, setShowOpenAIKey] = useState(false);
  const [showAnthropicKey, setShowAnthropicKey] = useState(false);
  const [showGoogleKey, setShowGoogleKey] = useState(false);

  const handleSave = (keyName, keyValue) => {
    localStorage.setItem(keyName, keyValue);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-1000">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl mb-4">🔑 Enter API Key:</h2>
        <p className="text-gray-600 mb-4">Your API Key is stored locally on your browser and never sent anywhere else.</p>

        <div className="mb-4">
          <label className="block text-gray-700">OpenAI API Key:</label>
          <div className="flex items-center mt-1">
            <input
              type={showOpenAIKey ? 'text' : 'password'}
              value={openAIKey}
              onChange={(e) => setOpenAIKey(e.target.value)}
              className="border p-2 rounded flex-1"
            />
            <button onClick={() => setShowOpenAIKey(!showOpenAIKey)} className="ml-2">
              {showOpenAIKey ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
          <button onClick={() => handleSave('openAIKey', openAIKey)} className="mt-2 bg-blue-500 text-white py-1 px-3 rounded flex items-center">
            <FaSave className="mr-2" /> Save
          </button>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Anthropic API Key:</label>
          <div className="flex items-center mt-1">
            <input
              type={showAnthropicKey ? 'text' : 'password'}
              value={anthropicKey}
              onChange={(e) => setAnthropicKey(e.target.value)}
              className="border p-2 rounded flex-1"
            />
            <button onClick={() => setShowAnthropicKey(!showAnthropicKey)} className="ml-2">
              {showAnthropicKey ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
          <button onClick={() => handleSave('anthropicKey', anthropicKey)} className="mt-2 bg-blue-500 text-white py-1 px-3 rounded flex items-center">
            <FaSave className="mr-2" /> Save
          </button>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Google Gemini API Key:</label>
          <div className="flex items-center mt-1">
            <input
              type={showGoogleKey ? 'text' : 'password'}
              value={googleKey}
              onChange={(e) => setGoogleKey(e.target.value)}
              className="border p-2 rounded flex-1"
            />
            <button onClick={() => setShowGoogleKey(!showGoogleKey)} className="ml-2">
              {showGoogleKey ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </button>
          </div>
          <button onClick={() => handleSave('googleKey', googleKey)} className="mt-2 bg-blue-500 text-white py-1 px-3 rounded flex items-center">
            <FaSave className="mr-2" /> Save
          </button>
        </div>

        <div className="text-gray-600 text-sm">
          <a href="#" className="flex items-center mb-2">
            <BsFillQuestionCircleFill className="mr-2" /> API Key not working? Click Here.
          </a>
          <a href="#" className="flex items-center">
            <BsFillQuestionCircleFill className="mr-2" /> Using Open Router? See instructions here.
          </a>
        </div>

        <div className="flex justify-end mt-4">
          <button onClick={onClose} className="bg-red-500 text-white py-1 px-3 rounded">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default APIModal;
