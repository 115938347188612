import React from 'react';
import ModelSelection from '../../components/common/ModelSelection';
import FunctionSelection from '../../components/common/FunctionSelection'

const Header = ({ toggleSidebar, selectedModel, setSelectedModel,selectedFunctions,setSelectedFunctions }) => {
  return (
    <header className="flex justify-between items-center p-4 fixed top-0 w-full bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg shadow-md z-40">
      <div className="flex items-center space-x-3">
        <button 
          className="bg-purple-500 text-white p-2 rounded md:hidden" 
          onClick={toggleSidebar} 
          aria-label="Toggle Sidebar"
        >
          <svg width="24" height="24" fill="currentColor">ß
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-10S17.52 2 12 2zM4 12c0-4.41 3.59-8 8-8s8 3.59 8 8-3.59 8-8 8-8-3.59-8-8zm7 1h2v6h-2zm0-8h2v6h-2z" />
          </svg>
        </button>
        <ModelSelection selectedModel={selectedModel} setSelectedModel={setSelectedModel} />
        <FunctionSelection selectedFunctions={selectedFunctions} setSelectedFunctions={setSelectedFunctions} />
      </div>
    </header>
  );
};

export default Header;
