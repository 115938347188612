export default async function image_generation_via_dalle_3(params, userSettings) {
    userSettings ={
      "quality": "standard",
      "resolution" :"1024x1024"
    }
    const openaikey = localStorage.getItem('openAIKey');
    const quality = getQuality(userSettings);
    const resolution = getResolution(userSettings);

    if (!openaikey) {
      throw new Error(
        'No OpenAI key provided to the DALL-E plugin. Please enter your OpenAI key in the plugin settings separately and try again.'
      );
    }

    const prompt = params.prompt;

    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', 'Bearer ' + openaikey);

    const raw = JSON.stringify({
      model: 'dall-e-3',
      prompt: prompt,
      n: 1,
      size: resolution,
      quality: quality,
      response_format: 'url',
    });

    const requestOptions = {
      method: 'POST',
      headers: requestHeaders,
      body: raw,
      redirect: 'follow',
    };

    const response = await fetch(
      'https://api.openai.com/v1/images/generations',
      requestOptions
    );
    if (response.status === 401) {
      throw new Error('Invalid OpenAI API Key. Please check your settings.');
    }

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText);
    }

    const data = await response.json();
    const url = data.data[0].url;

    // Generate markdown to display the image
    const endresult = '![' + prompt.replace(/[[]]/, '') + '](' + url + ')';
    return endresult;
  }

  function getQuality(userSettings) {
    if (!userSettings.quality) {
      return 'standard';
    }

    switch (userSettings.quality.toLowerCase()) {
      case 'hd':
        return 'hd';
      case 'standard':
        return 'standard';
      default:
        return 'standard';
    }
  }

  function getResolution(userSettings) {
    switch (userSettings.resolution) {
      case '1024x1024':
        return '1024x1024';
      case '1024x1792':
        return '1024x1792';
      case '1792x1024':
        return '1792x1024';
      default:
        return '1024x1024';
    }
  }